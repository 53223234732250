import jwt from "jsonwebtoken";
import {CookieHandler} from "../../utils/CookieHelper";

const isBrowser = typeof window !== 'undefined';

const categories = [
  {
    "_id": "5fb8fb9638396325a111412a",
    "title": "Home Decor",
    "slug": "home-decor",
    "icon": "decore_icon.png",
    "featured": true
  },
  {
    "_id": "6089318861717ea84b9420ca",
    "title": "Garden",
    "slug": "garden",
    "icon": "outdoor_icon.png",
    "featured": true
  },
  {
    "_id": "608931c22f5f488d8ad086fb",
    "title": "Table Ware/Serveware/Barware",
    "slug": "table-ware-serveware-barware",
    "icon": "decor_icon.png",
    "featured": true
  },
  {
    "_id": "608931bef3985e1546d5174a",
    "title": "Accent Furniture",
    "slug": "accent-furniture",
    "icon": "barware_icon.png",
    "featured": true
  }
];
const subCategories = [
  {
    "_id": "60ffd22be95231e25357423d",
    "title": "Candle Holders",
    "slug": "candle-holders",
    "icon": "decore_icon.png",
    "parentCategoryId": "5fb8fb9638396325a111412a"
  },
  {
    "_id": "60ffd2379f65104fd94a1676",
    "title": "Lanterns",
    "slug": "lanterns",
    "icon": "outdoor_icon.png",
    "parentCategoryId": "5fb8fb9638396325a111412a"
  },
  {
    "_id": "60ffd23b07bd1bce33f9d7c5",
    "title": "Hurricanes",
    "slug": "hurricanes",
    "icon": "decor_icon.png",
    "parentCategoryId": "5fb8fb9638396325a111412a"
  },
  {
    "_id": "60ffd240933006ab96a33b27",
    "title": "Votives",
    "slug": "votives",
    "icon": "barware_icon.png",
    "parentCategoryId": "5fb8fb9638396325a111412a"
  },
  {
    "_id": "60ffd24413c7c3be934c9198",
    "title": "Vases",
    "slug": "vases",
    "icon": "barware_icon.png",
    "parentCategoryId": "5fb8fb9638396325a111412a"
  },
  {
    "_id": "60ffd249f41cb847a22e76fa",
    "title": "Wax Votives",
    "slug": "wax-votives",
    "icon": "barware_icon.png",
    "parentCategoryId": "5fb8fb9638396325a111412a"
  },
  {
    "_id": "60ffd24dc82f39d267be4a6d",
    "title": "Misc and Seasonal",
    "slug": "misc-and-seasonal",
    "icon": "barware_icon.png",
    "parentCategoryId": "5fb8fb9638396325a111412a"
  },
  {
    "_id": "60ffd2518c964c856de8dcfd",
    "title": "Plant Stands with Planters",
    "slug": "plant-stands-with-planters",
    "icon": "barware_icon.png",
    "parentCategoryId": "6089318861717ea84b9420ca"
  },
  {
    "_id": "60ffd257bea49ff8b43a2560",
    "title": "Promotion Planters",
    "slug": "promotion-planters",
    "icon": "barware_icon.png",
    "parentCategoryId": "6089318861717ea84b9420ca"
  },
  {
    "_id": "60ffd25b38f21cf6d1c3f532",
    "title": "Flower Pots & Vases",
    "slug": "flower-pots-vases",
    "icon": "barware_icon.png",
    "parentCategoryId": "6089318861717ea84b9420ca"
  },
  {
    "_id": "60ffd25ee7a6da5755607a74",
    "title": "Bird Houses, Green Houses",
    "slug": "bird-houses-green-houses",
    "icon": "barware_icon.png",
    "parentCategoryId": "6089318861717ea84b9420ca"
  },
  {
    "_id": "60ffd263a7a24ce930fbf774",
    "title": "Candle Sticks, Cones",
    "slug": "candle-sticks-cones",
    "icon": "barware_icon.png",
    "parentCategoryId": "6089318861717ea84b9420ca"
  },
  {
    "_id": "60ffd2666f01ba8c26e325f9",
    "title": "Plates",
    "slug": "plates",
    "icon": "barware_icon.png",
    "parentCategoryId": "608931c22f5f488d8ad086fb"
  },
  {
    "_id": "60ffd26a68a78954a853e161",
    "title": "Bowls",
    "slug": "bowls",
    "icon": "barware_icon.png",
    "parentCategoryId": "608931c22f5f488d8ad086fb"
  },
  {
    "_id": "60ffd270b765df6424c7373d",
    "title": "Trays, Platters",
    "slug": "trays-platters",
    "icon": "barware_icon.png",
    "parentCategoryId": "608931c22f5f488d8ad086fb"
  },
  {
    "_id": "60ffd274c32d4e04df21638b",
    "title": "Trivets, Coaster",
    "slug": "trivets-coaster",
    "icon": "barware_icon.png",
    "parentCategoryId": "608931c22f5f488d8ad086fb"
  },
  {
    "_id": "60ffd2799b08316a76ac4d1b",
    "title": "Teapot",
    "slug": "teapot",
    "icon": "barware_icon.png",
    "parentCategoryId": "608931c22f5f488d8ad086fb"
  },
  {
    "_id": "60ffd27d7af34d6d443bc564",
    "title": "Chopping Boards",
    "slug": "chopping-boards",
    "icon": "barware_icon.png",
    "parentCategoryId": "608931c22f5f488d8ad086fb"
  },
  {
    "_id": "60ffd285bc2cdc9a83a7d9f8",
    "title": "Cake Stands",
    "slug": "cake-stands",
    "icon": "barware_icon.png",
    "parentCategoryId": "608931c22f5f488d8ad086fb"
  },
  {
    "_id": "60ffd28f0c073f359db1b419",
    "title": "Stools",
    "slug": "stools",
    "icon": "barware_icon.png",
    "parentCategoryId": "608931bef3985e1546d5174a"
  },
  {
    "_id": "60ffd29306d40a5512fabf47",
    "title": "Side Tables",
    "slug": "side-tables",
    "icon": "barware_icon.png",
    "parentCategoryId": "608931bef3985e1546d5174a"
  },
  {
    "_id": "60ffd298988d9034ecda88fd",
    "title": "Coffee Tables",
    "slug": "coffee-tables",
    "icon": "barware_icon.png",
    "parentCategoryId": "608931bef3985e1546d5174a"
  },
  {
    "_id": "60ffd2a061dd55b6e8ca5f0a",
    "title": "Shelves/Racks",
    "slug": "shelves-racks",
    "icon": "barware_icon.png",
    "parentCategoryId": "608931bef3985e1546d5174a"
  },
  {
    "_id": "60ffd2a480636630f801990a",
    "title": "Baskets",
    "slug": "baskets",
    "icon": "barware_icon.png",
    "parentCategoryId": "608931bef3985e1546d5174a"
  }
];
export const initialState = () => {
  let pct = undefined;
  try {
    pct = isBrowser && jwt.decode(localStorage.getItem("pct"));
  } catch (e) {

  }
  return {
    searchTerm: '',
    isSticky: false,
    isSidebarSticky: true,
    isDrawerOpen: false,
    isModalOpen: false,
    appAuthenticated: !!pct,
    pct,
    companyName: pct ? pct.name : "",
    currencySymbol: ((pct && pct.currency && pct.currency.symbol) || ""),
    categories: categories,
    subCategories: subCategories,
    materialCategories: ((pct && pct.materialCategories) || []),
    storeMaterials: ((pct && pct.storeMaterials) || []),
    banners: ((pct && pct.banners) || []),
    socialLinks: ((pct && pct.socialLinks) || []),
    addToCartBtnText: ((pct && pct.productSettings && pct.productSettings.addToCartBtnText) || "Add To Cart"),
  }
};

export function appReducer(state, action) {
  switch (action.type) {
    case 'SET_SEARCH_TERM':
      return {
        ...state,
        searchTerm: action.payload,
      };
    case 'SET_STICKY':
      return {
        ...state,
        isSticky: true,
      };
    case 'REMOVE_STICKY':
      return {
        ...state,
        isSticky: false,
      };
    case 'SET_SIDEBAR_STICKY':
      return {
        ...state,
        isSidebarSticky: true,
      };
    case 'REMOVE_SIDEBAR_STICKY':
      return {
        ...state,
        isSidebarSticky: false,
      };
    case 'TOGGLE_DRAWER':
      return {
        ...state,
        isDrawerOpen: !state.isDrawerOpen,
      };
    case 'TOGGLE_MODAL':
      return {
        ...state,
        isModalOpen: !state.isModalOpen,
      };
    case 'SET_PCT':
      localStorage.setItem('pct', action.payload);
      let decoded = isBrowser && !!action.payload ? jwt.decode(action.payload) : undefined;
      let guestLogin = CookieHandler.getCookieValue('guestLogin');

      if (!guestLogin && !!(decoded.customerLoginPreferences && decoded.customerLoginPreferences.loginRequired)) {
        CookieHandler.setCookie('guestLogin', true, 365);
      }

      return {
        ...state,
        appAuthenticated: !!decoded,
        pct: decoded,
        companyName: decoded && decoded.name || "",
        currencySymbol: decoded.currency && decoded.currency.symbol || "",
        categories: categories || [],
        subCategories: subCategories || [],
        materialCategories: decoded.materialCategories || [],
        storeMaterials: decoded.storeMaterials || [],
        banners: decoded.banners || [],
        socialLinks: ((decoded.socialLinks) || []),
        addToCartBtnText: ((decoded.productSettings && decoded.productSettings.addToCartBtnText) || "Add To Cart"),
      };
    default: {
      throw new Error(`Unsupported action type at App Reducer`);
    }
  }
}
