import _ from 'lodash';
import download from "downloadjs";
import {toast as Toast} from "react-toastify";

export const downloadFileDirectlyFromURL = (url, name, type) => {
  try {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.download = name;
    a.href = url;
    a.click();
    document.body.removeChild(a);
  } catch (e) {
    console.log('downloadFileDirectlyFromURL e = ', e)
  }
};

export const Delay = async (ts = 500) => {
  return await new Promise((resolve, reject) => {
    setTimeout(resolve, ts)
  })
}

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export const removeTypename = (value) => {
  if (value === null || value === undefined) {
    return value;
  } else if (Array.isArray(value)) {
    return value.map(v => removeTypename(v));
  } else if (typeof value === 'object') {
    const newObj = {};
    Object.entries(value).forEach(([key, v]) => {
      if (key !== '__typename') {
        newObj[key] = removeTypename(v);
      }
    });
    return newObj;
  }
  return value;
};

export const CONSOLE_LOG = (str, data) => {
  try {
    process.env.console || localStorage.PRINT_LOG ?
      console.log(str, data) : null;
  } catch (e) {
    process.env.console || localStorage.PRINT_LOG ?
      console.log('error in printing logs = ', e) : null;
  }
};

export const convertLightStringToNumber = (lights = []) => {

  let updatedLights = lights.map(light => {
    Object.keys(light).map(key => {
      let type = typeof light[key];
      if (['intensity', 'distance', 'decay', 'angle', 'penumbra'].includes(key) && type === "string" && _.isNumber(_.toNumber(light[key]))) {
        light[key] = _.toNumber(light[key]);
      } else if (['position'].includes(key) && type === "object") {
        let keys = light[key] && Object.keys(light[key]);
        if (keys && keys.length) {
          keys.forEach(it => {
            if (['x', 'y', 'z'].includes(it) && typeof light[key][it] === "string" && _.isNumber(_.toNumber(light[key][it]))) {
              light[key][it] = _.toNumber(light[key][it]);
            }
          })
        }
      }
    });
    return {...light};
  });

  return updatedLights;
}

export const checkImageDataURL = (imageURL) => {
  let dataRegEx = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
  return !!(imageURL && typeof imageURL === 'string' && !!imageURL.match(dataRegEx));
};

export const checkImageDataBLOB = (imageURL) => {
  return !!(imageURL && typeof imageURL === 'string' && (imageURL.includes('blob:')));
};

export const updateImageURL = (imageURL) => {
  return imageURL ? (checkImageDataURL(imageURL) || checkImageDataBLOB(imageURL)) ? imageURL : !imageURL.includes('http') ? `${process.env.CLOUD_FRONT_URL}${imageURL}` : imageURL : undefined;
};

export const imageResizeLambda = (data) => {

}

let regExp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/i;
export const replaceURLWithHTMLLinks = (string) => {
  return string.replace(regExp, `<a href='$1'>$1</a>`);
}

export const lowerCase = function (str) {
  return str.toLowerCase();
};

export const companyWordCase = function (str = '') {
  return lowerCase(str).split(' ')
    .map(word => word.charAt(0).toUpperCase())
    .join(' ');
};

export const downloadFileFromURL = (url, name, type) => {
  var x = new XMLHttpRequest();
  x.open("GET", url, true);
  x.responseType = 'blob';
  x.onload = function (e) {
    download(x.response, name, type);
  }
  x.send();
};

export const validateNumber = (inputValue, defaultValue) => {
  try {
    let value = Number(inputValue);
    if (!isNaN(value) && inputValue.length) {
      return value;
    } else {
      return defaultValue;
    }
  } catch (e) {
    return defaultValue
  }
}

export const appendHtmlTagToBody = async (tag, id, link, callback, attrs) => {
  try {

    var ele = document.createElement(tag);
    ele.id = id;

    tag === 'script' ? ele.src = link : ele.href = link;
    if (tag === 'link') {
      ele.type = link;

      ele.rel = 'stylesheet';

      ele.type = 'text/css';
    }
    id === 'AndroidAR' ? ele.type = 'module' : null;

    attrs && Object.keys(attrs) && Object.keys(attrs).length ? Object.keys(attrs).map(key => {
      ele.setAttribute([key], attrs[key]);
    }) : null;

    new Promise(function (resolve, reject) {
      ele.onload = function () {
        callback && callback();
        resolve();
      };
      ele.onerror = function (event) {
        CONSOLE_LOG(`${tag} ${id} load error = `, event);
        reject(event);
      };
    });

    if (tag === 'link')
      document.getElementsByTagName('HEAD')[0].appendChild(ele);
    else
      document.body.appendChild(ele);

  } catch (e) {
  }

};

export const getURLParamsFromSearchString = (searchString) => {
  let params = {};
  let query = searchString.substring(1);
  let vars = query.split('&');
  if (vars.length > 0) {
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split('=');
      if (pair[0] && pair[1]) {
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
    }
  }
  return params;
};

export const getURLParams = (url) => {
  let params = {};
  let parser = document.createElement('a');
  parser.href = url;
  let query = parser.search.substring(1);
  let vars = query.split('&');
  if (vars.length > 0) {
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split('=');
      if (pair[0] && pair[1]) {
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
    }
  }
  return params;
};

export const preserveQueryParameters = (fromURL, toURL) => {
  let params = getURLParamsFromSearchString(fromURL);
  Object.assign(params, getURLParams(toURL))
  let stringQueryParameters = _.join(_.map(Object.keys(params), (key) => {
    return `${key}=${params[key]}`
  }), '&');
  return stringQueryParameters;
}

const FormatGraphQlError = (nStr = '') => {
  nStr = nStr.replace('GraphQL error: ', '').trim();
  nStr = nStr.replace('Context creation failed: ', '').trim();
  return nStr;
};


export const ErrorHandler = (err, calback) => {
  if (!(err.networkError
    && err.networkError.result
    && err.networkError.result.errors
    && err.networkError.result.errors[0]
    && (err.networkError.result.errors[0].message.includes('You do not have permission') || err.networkError.result.errors[0].message.includes('Your account is inactive, contact admin')))
  ) {
    Toast.error(FormatGraphQlError(err.message));
  }
  calback && calback();
};