import React, {Component, Fragment} from "react";
import _ from "lodash";
import uuid4 from 'uuid4';

class Reply extends Component {

  constructor(props) {
    super(props);

    this.state = {
      input: ""
    };

    this.isTyping = false;
    this.stopTypingTimeout = undefined;

  }

  componentDidMount() {
    // let chatChannel = localStorage.getItem(this.props.companyId);
    // chatChannel = chatChannel && JSON.parse(chatChannel)
    // if (chatChannel && chatChannel.channelId) {
    //   this.locateUser(chatChannel.channelId);
    // }
  }

  // sendSysMessage = async (channel, text, type, itemDetail) => {
  //   let message = {
  //     message_id: uuid4(),
  //     channel: channel,
  //     text: text,
  //     uuid: this.props.chatHelper.getUUID(),
  //     user_type: "sys",
  //     content_type: "text",
  //     user_name: "",
  //     deleted: false,
  //     is_updated: false,
  //     item_detail: {
  //       ...itemDetail,
  //       type: type
  //     }
  //   };
  //   await this.props.chatHelper.publishToChannel(channel, message);
  // }

  sendMessage = async (e) => {
    e && e.preventDefault();

    let {user, userName} = this.props;
    let user_name = "User";
    if (user && user.firstName && user.lastName) {
      user_name = `${user.firstName} ${user.lastName}`;
    } else if (userName) {
      user_name = userName;
    }

    let channel = this.props.channel;

    let message = {
      message_id: uuid4(),
      channel: channel,
      text: this.state.input,
      uuid: this.props.chatHelper.getUUID(),
      user_type: "visitor",
      content_type: "text",
      user_name: user_name,
      deleted: false,
      is_updated: false
    };

    this.props.chatHelper.publishToChannel(channel, message);

    this.setState({input: ""});

  };

  resetStopTypingTimeout = () => {
    if (this.stopTypingTimeout) {
      clearTimeout(this.stopTypingTimeout);
    }
    this.stopTypingTimeout = setTimeout(() => {
      this.isTyping = false;
      this.setTypingState(this.isTyping);
      this.stopTypingTimeout = undefined;
    }, 3000);
  };

  handleSendMessage = async (e) => {
    e && e.preventDefault();
    this.isTyping = false;
    this.setTypingState(this.isTyping);
    if (this.state.input && this.state.input.trim().length > 0) {
      await this.sendMessage(e);
    }
  };

  handleChangeInput = (e) => {
    e && e.preventDefault();
    this.setState({input: e.target.value});
    const isInputEmpty = (e.target.value.length === 0);

    if (isInputEmpty === false) {
      // If the user wasn't typing before, and now the event has fired,
      // it means they are now typing
      if (this.isTyping === false) {
        this.isTyping = true;
        this.setTypingState(this.isTyping);
        // Start a 3 second countdown to see if they type within that window
        this.resetStopTypingTimeout();
      } else {
        // If the user typed another character, reset the timeout
        this.resetStopTypingTimeout();
      }
    } else {
      if (this.isTyping === true) {
        // If the user was typing, but now the input is empty,
        // it means they've deleted everything and that triggered
        // an onChange event.  For this, we state they have stopped typing
        this.isTyping = false;
        this.setTypingState(this.isTyping);
        // Stop the timeout, if there is one running
        if (this.stopTypingTimeout) {
          clearTimeout(this.stopTypingTimeout);
          this.stopTypingTimeout = undefined;
        }
      }
    }

  };

  setTypingState = (isTyping) => {

    // let chatChannel = localStorage.getItem(this.props.companyId);
    let user_name = "User";
    // if (JSON.parse(chatChannel) && (JSON.parse(chatChannel)).user && (JSON.parse(chatChannel)).user.email) {
    //   user_name = (JSON.parse(chatChannel)).user.email;
    // }
    const {user, userName} = this.props;
    if (user && user.firstName && user.lastName) {
      user_name = `${user.firstName} ${user.lastName}`
    } else if (userName) {
      user_name = userName;
    }

    this.props.chatHelper.pubnub.setState({
      channels: [this.props.channel],
      uuid: this.props.chatHelper.getUUID(),
      state: {isTyping, userType: "visitor", name: user_name},
    }, function (status) {
      // handle state setting response
    });
  };

  // onChatFileUpload = (e) => {
  //   e && e.preventDefault();
  //   if (e.target && e.target.files && e.target.files.length) {
  //     const {companyId, channel} = this.props;
  //     let file = e.target.files[0];
  //     let fileExtension = (_.reverse(_.split(file.name, '.')))[0];
  //
  //     let postBody = {
  //       fileExtension: fileExtension,
  //       channel: channel
  //     };
  //
  //     this.apiFactory.getSignedUrl(file, postBody).then((data) => {
  //       if (data && data.data && data.data.signedUrl) {
  //         var xhr = new XMLHttpRequest();
  //         xhr.open('PUT', data.data.signedUrl, true);
  //         xhr.onload = () => {
  //           if (xhr.status === 200) {
  //             this.generateThumb(data.data.filename, file, channel);
  //           }
  //         };
  //         xhr.onerror = (e) => {
  //           // error...
  //           CONSOLE_LOG("xhr error : ", e);
  //         };
  //         xhr.send(file);
  //       }
  //     });
  //
  //   }
  // };
  //
  // generateThumb = async (url, file, channel) => {
  //
  //   const {companyId} = this.props;
  //
  //   let chatChannel = localStorage.getItem(this.props.companyId);
  //   let user_name = "Visitor";
  //   if (JSON.parse(chatChannel) && (JSON.parse(chatChannel)).user && (JSON.parse(chatChannel)).user.email) {
  //     user_name = (JSON.parse(chatChannel)).user.email;
  //   }
  //
  //   let fileType = file.type.split('/');
  //
  //   let message = {
  //     message_id: uuid4(),
  //     channel: channel,
  //     text: "",
  //     uuid: this.props.chatHelper.getUUID(),
  //     user_type: "visitor",
  //     content_type: "file",
  //     file_type: file.type,
  //     file_size: file.size,
  //     file_name: file.name,
  //     file: url,
  //     user_name: user_name,
  //     deleted: false,
  //     is_updated: false
  //   };
  //
  //   if (fileType && fileType[0] === 'image') {
  //
  //     let param = {};
  //     let pathInterval = (url).split('/');
  //     let path = pathInterval.slice(0, (pathInterval.length) - 1);
  //     let finalPath = path.join('/');
  //     let thumb = path.join('/') + '/thumbnail';
  //     param.originpath = 'public/' + finalPath;
  //     param.key = pathInterval[pathInterval.length - 1];
  //     param.size = '400';
  //     param.destinationpath = 'public/' + thumb;
  //     await imageResizeLambda(param);
  //     message.file_thumb = thumb + '/' + pathInterval[pathInterval.length - 1];
  //     message.text = "Photo"
  //
  //   } else {
  //     message.text = "File"
  //   }
  //
  //   if (chatChannel && JSON.parse(chatChannel) && (JSON.parse(chatChannel)).channelId) { // chat exist continue with it
  //
  //     chatChannel = JSON.parse(chatChannel);
  //     chatChannel._id ? message["chat_id"] = chatChannel._id : null;
  //
  //     this.props.chatHelper.publishToChannel(channel, message);
  //     this.props.chatHelper.pushNotification(`alert.${this.props.companyId}`, message);
  //
  //   } else { // initialize new conversation
  //
  //     const {type, itemDetail} = this.props;
  //     const typeWithEncryptedIds = ["collection", "image", "quotation", "showroom"];
  //
  //     this.sendSysMessage(channel, "Chat started")
  //     if (type && itemDetail) {
  //       this.sendSysMessage(
  //         channel,
  //         `Visitor navigated to ${itemDetail.uniqueCode} ${itemDetail.name} - ${LiveChatTypeHelper[type] || type}`,
  //         type,
  //         {
  //           _id: typeWithEncryptedIds.includes(type) ? itemDetail.key : itemDetail._id,
  //           name: itemDetail.name,
  //           uniqueCode: itemDetail.uniqueCode,
  //           thumb: itemDetail.thumb,
  //           companyId: itemDetail.companyId,
  //           shareId: itemDetail.shareId,
  //         }
  //       );
  //     }
  //     await this.props.chatHelper.publishToChannel(channel, message);
  //     this.props.chatHelper.pushNotification(`alert.${this.props.companyId}`, message);
  //
  //     let emailMessage = {
  //       message_id: uuid4(),
  //       channel: channel,
  //       text: "Thanks. We'll be in touch as soon as possible.",
  //       uuid: "",
  //       content_type: "emailForm",
  //       user_type: "form",
  //       user_name: "",
  //       deleted: false,
  //       is_updated: false
  //     };
  //
  //     await this.props.chatHelper.publishToChannel(channel, emailMessage);
  //     await this.createInboxUser({channel, message});
  //
  //   }
  //
  // };
  //
  // triggerInputFile = () => {
  //   this.fileInput.click();
  // };

  render() {
    return (
      <>

        <div className="reply border p-5">
          <div className="heading">
            <h3 className="title">Leave A Comment</h3>
          </div>

          <form onSubmit={this.handleSendMessage}>
            <label htmlFor="reply-message" className="sr-only">Comment</label>
            <textarea
              name="reply-message"
              id="reply-message"
              cols="30"
              rows="4"
              className="form-control"
              required
              placeholder="Comment *"
              onChange={this.handleChangeInput}
              value={this.state.input}
            />

            {/*<div className="row">*/}
            {/*  <div className="col-md-6">*/}
            {/*    <label htmlFor="reply-name" className="sr-only">Name</label>*/}
            {/*    <input type="text" className="form-control" id="reply-name" name="reply-name" required placeholder="Name *" />*/}
            {/*  </div>*/}

            {/*  <div className="col-md-6">*/}
            {/*    <label htmlFor="reply-email" className="sr-only">Email</label>*/}
            {/*    <input type="email" className="form-control" id="reply-email" name="reply-email" required placeholder="Email *" />*/}
            {/*  </div>*/}
            {/*</div>*/}

            <button type="submit" className="btn btn--primary btn--rounded" onClick={this.handleSendMessage}>
              POST COMMENT
            </button>
          </form>
        </div>

        {/*<div className="rcw-sender align-items-center d-flex">*/}

        {/*  <input*/}
        {/*    className='chatFileUploader d-none' multiple={false}*/}
        {/*    ref={node => this.fileInput = node} type="file" onChange={this.onChatFileUpload}*/}
        {/*  />*/}

        {/*  <form className="rcw-new-message w-100" onSubmit={this.handleSendMessage}>*/}

        {/*    <input*/}
        {/*      type="text"*/}
        {/*      placeholder="Leave a comment..."*/}
        {/*      autoComplete="off"*/}
        {/*      className="form-control chatInput m-0"*/}
        {/*      id="chatInput"*/}
        {/*      name="chatInput"*/}
        {/*      onChange={this.handleChangeInput}*/}
        {/*      value={this.state.input}*/}
        {/*    />*/}

        {/*  </form>*/}

        {/*  /!*<button id={"rcw-file-upload"} className="rcw-send rcw-send-file" onClick={(e) => {*!/*/}
        {/*  /!*  this.triggerInputFile()*!/*/}
        {/*  /!*}}>*!/*/}
        {/*  /!*  <i className="simple-icon-paper-clip lead text-muted"/>*!/*/}
        {/*  /!*</button>*!/*/}

        {/*  <button id={"rcw-send"} type="submit" className="rcw-send" onClick={this.handleSendMessage}>*/}
        {/*    <img*/}
        {/*      style={{height: '25px'}}*/}
        {/*      src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgNTM1LjUgNTM1LjUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUzNS41IDUzNS41OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPGcgaWQ9InNlbmQiPgoJCTxwb2x5Z29uIHBvaW50cz0iMCw0OTcuMjUgNTM1LjUsMjY3Ljc1IDAsMzguMjUgMCwyMTYuNzUgMzgyLjUsMjY3Ljc1IDAsMzE4Ljc1ICAgIiBmaWxsPSIjY2JjYmNiIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg=="*/}
        {/*      className="rcw-send-icon" alt="send"*/}
        {/*    />*/}
        {/*  </button>*/}

        {/*</div>*/}
      </>
    )
  }

}

export default Reply;
